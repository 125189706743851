@use "../../global-styles/colors";
@use "../../global-styles/breakpoints";

.success-stories-expand-button {
    width: 30px;
    height: 30px;
  }

.card-container {
  span {
    font-size: 16px;
  }
}

.upload-quarterly-report-container {
  & .input-modal {
    width: auto;
    min-width: 420px;
  }
  gap: 16px;
  @media (max-width: breakpoints.$breakpoint-large-screen) {
    flex-direction: column;
    width: 80%;
    max-width: 450px;
    & .card-container{
      margin-right: 0px;
      width: 100%;
    }
  }
  @media (max-width: breakpoints.$breakpoint-tablet) {
    & .input-modal {
      width: 300px;
      margin: 12px;
      & .input-file-main-container{
        width: auto;
      }
    }
  }

  & .input-file-main-container{
    border-left: none;
    padding-left: 0px;
    height: auto;
    margin-bottom: 24px;
  }
}

.investor-profile-card-container{
  @media (max-width: breakpoints.$breakpoint-large-screen) {
    flex-direction: column;
    width: 80%;
    max-width: 450px;
    & .card-container{
      margin-right: 0px;
      width: 100%;
    }
  }
}

.investment-warning-sub-heading{
  color: colors.$red-alpha;
  background-color: colors.$red-delta ;
  padding: 16px 12px;
  border-radius: 8px;
  p{
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
  }
}

.investment-success-sub-heading{
  color: colors.$green-alpha;
  background-color: colors.$green-bravo ;
  padding: 16px 12px;
  border-radius: 8px;
  p{
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
  }
}