@use "../../../global-styles/mixins";

.navigation-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 20;
  background-color: #fff;
}
.navigation {
  justify-content: space-between;
  position: relative;
  display: flex;
  align-items: center;
  height: 48px;
  width: auto;
  background-color: #fff;
  color: black;
  border-bottom: 1px solid #e2e2e2;
  padding-left: 48px;
  padding-top: 24px;
  padding-bottom: 24px;
}

.brand-name {
  text-decoration: none;
  color: black;
  font-size: 1.3rem;
  margin-left: 1rem;
}

.navigation-menu {
  flex: 0.3;
  justify-content: end;
  display: flex;
}

.navigation-menu .nav-list {
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: flex-end;
  margin-right: 34px;
  column-gap: 12px;
}

.navigation-menu .nav-item {
  list-style-type: none;
  margin: 0 1rem;
  width: max-content;
}

.navigation-menu .nav-item a {
  text-decoration: none;
  display: block;
  width: 100%;
}

.hamburger {
  border: 0;
  height: 40px;
  width: 40px;
  /* padding: 0.5rem; */
  border-radius: 50%;
  background-color: white;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  right: 73px;
  transform: translateY(-50%);
  display: none;
}
@media screen and (max-width: 992px) {
  .hamburger {
    display: block;
  }

  .navigation-menu .nav-list {
    flex-direction: column;
    position: absolute;
    top: 48px;
    left: 0;
    width: 100%;
    height: calc(100vh - 60px);
    display: none;
  }

  .navigation-menu .nav-item {
    text-align: center;
    margin: 0;
    width: 100%;
  }

  .navigation-menu .nav-item a {
    color: #545a5e;
    @include mixins.font(400);
    font-size: 16px;
    line-height: 140%;
    width: 100%;
    padding: 1.5rem 0;
  }
  .navigation-menu .nav-item .nav-link-bold {
    @include mixins.font(600);
  }
  .navigation-menu.expanded .nav-list {
    display: block;
    margin-top: 45px;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 8px;
  }
  .navigation-menu.expanded .nav-item {
    border-bottom: 1px solid #e2e2e2;
  }
  .navigation-menu.expanded{
  z-index: 10;
  }
}

.navbar-btn-padding{
  padding: 0px 14px;
}
