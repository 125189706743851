@use '../../../global-styles/colors';
@use '../../../global-styles/mixins';



.input-file {
  display: none;
  &-main-container {
    display: flex;
    width: 491px;
    max-width: 491px;
    flex-direction: column;
    height: 152px;
    border-left: 8px solid colors.$light-gray-alpha;
    padding-left: 24px;
    & > span {
      @include mixins.font(600);
      font-size: 18px;
      line-height: 150%;
      color: colors.$dark-gray-bravo;
      margin-bottom: 24px;
    }
  }
  &-container {
    display: flex;
    align-items: center;
    padding-left: 24px;
    padding-right: 25px;
    color: colors.$dark-gray-alpha;
    height: 68px;
    background-color: colors.$light-gray-foxtrot;
    border: 1px dashed colors.$dark-gray-alpha;
    border-radius: 6px;
  }
  &-label-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin-left: 25px;
  }
  &-label {
    @include mixins.font(700);
    font-size: 14px;
    line-height: 100%;
  }
  &-sub-label {
    @include mixins.font(400);
    font-size: 14px;
    line-height: 100%;
  }
}

.selected {
  border-style: solid;
  border-color: colors.$light-gray-alpha;
}
.input-file-delete-icon{
  z-index: 5;
  &:hover {
    cursor: pointer;
  }
}

.file-list {
  display: flex;
  gap: 8px;
  justify-content: space-between;

  span {
    width: 90%;
    word-break: break-all;
  }

  svg {
    cursor: pointer;
  }
}