@use "../../../global-styles/colors";
@use "../../../global-styles/breakpoints";

.card-container {
    border: 1px solid colors.$light-gray-alpha;
    border-radius: 8px;
    width: 100%;
    max-width: 450px;
    height: auto;
    display: inline-block;
    overflow: auto;
    @media (max-width: breakpoints.$breakpoint-mobile) {
        max-width: none;
        width: auto;
      }
}

