@use '../../global-styles/colors';
@use '../../global-styles/breakpoints';

.inquiries-toolbar-layout {
  display: grid;
  width: -webkit-fill-available;
  grid-template-columns: 2.5fr 1.7fr 1.3fr;
  .sort-reset {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  @media (max-width: breakpoints.$breakpoint-tablet) {
    display: flex;
    flex-direction: column;
    gap: 0;
    width: -webkit-fill-available;
    .sort-reset {
      display: flex;
      gap: 20px;
    }
  }
}
