@use "../../../global-styles/colors";
@use '../../../global-styles/mixins';

.basic, .default {
    font-size: 16px;
    color: colors.$light-gray-echo;
    line-height: 150%;
}
.default{
    @include mixins.font(500);
    color: colors.$dark-gray-bravo;
    font-size: 18px;
}
.bold{
    @include mixins.font(700);
    color: colors.$dark-gray-bravo;
    font-size: 18px;
}
.large{
    @include mixins.font(700);
    color: colors.$dark-gray-bravo;
    font-size: 24px;
}
.thin{
    @include mixins.font(400);
    color: colors.$dark-gray-bravo;
    font-size: 18px;
}
.primary-hover{
    &:hover{
        color: colors.$red-alpha;
    }
}
.center{
    text-align: center;
}