@use '../../../global-styles/colors';

.success {
  fill: colors.$success;
}
.warning {
  fill: colors.$orange-alpha;
}
.progress {
  fill: colors.$blue-alpha;
}
.error {
  fill: colors.$error;
}
.default {
  fill: colors.$dark-gray-bravo;
}
.red {
  fill: colors.$red-alpha;
}

.default-light {
  fill: colors.$light-gray-echo;
}
.warning-banner {
  stroke: colors.$orange-alpha;
}
.error-banner {
  stroke: colors.$red-alpha;
}
.success-banner {
  stroke: colors.$green-alpha;
}

.button-icon {
  & path {
    fill: colors.$white;
  }
}
