@use "../../../global-styles/colors";
@use "../../../global-styles/mixins";
@use "../../../global-styles/breakpoints";

.modal-container{
    height: 100vh;
    width: 100vw;
    background-color: rgba($color: black, $alpha: 0.3);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    display: none;
    justify-content: center;
    align-items: center;
    display: flex;
}
.input-modal{
    width: 413px;
    height: fit-content;
    background-color: white;
    border-radius: 8px;
    border: 1px solid colors.$light-gray-alpha;
    padding: 24px 32px;
}

@media (max-width: breakpoints.$breakpoint-mobile) {
    .input-modal{
        margin: 12px;
    }
}
