@use "../../../global-styles/colors";
@use "../../../global-styles/mixins";
@use "../../../global-styles/breakpoints";


.project-card-container{
    @include mixins.font();
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid colors.$light-gray-alpha;
    background-color: colors.$white;
    width: 412px;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    &:hover{
        background-color: colors.$red-alpha;
        color: colors.$white;
        .tag-container{
            background-color: colors.$white;
            color: colors.$black;
        }
        .card-main, .card-sub{
            color: colors.$white;
        }
        .title{
            color: colors.$white;
        }
    }
    &:focus{
        outline: none;
        background-color: colors.$red-alpha;
        box-shadow: 0px 0px 0px 8px rgba(245, 59, 77, 0.4);
        color: colors.$white;
        .tag-container{
            background-color: colors.$white;
            color: colors.$black;
        }
        .card-main, .card-sub{
            color: colors.$white;
        }
        .title{
            color: colors.$white;
        }
    }
    @media (max-width: breakpoints.$breakpoint-mobile) {
        width: 92vw;
    }
}

.p-2{
    padding: 2rem;
}

.tags{
    display: flex;
    gap: 10px;
}

.flex{
    display: flex;
}

.space-between{
    justify-content: space-between;
}

.title-container{
    display: flex;
    flex-direction: column;
    gap: 12px;
    .title{
        transition: all 200ms ease-in-out;
        font-size: 24px;
        font-weight: 700;
        color: colors.$dark-gray-bravo;
    &:hover{
        color: colors.$white;
    }
    }
    &.right{
        font-weight: 700;
        font-size: 14px;
        text-align: right;
        span{
            font-size: 16px;
        }
    }
}

.description-container{
    p{
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }
    @media (max-width: breakpoints.$breakpoint-mobile) {
        text-align: center;
    }
}

.stats-card{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    border: 1px solid colors.$light-gray-alpha;
    border-radius: 10px;
    width: 158px;
    height: 105px;
    .card-main{
        transition: all 200ms ease-in-out;
        font-size: 32px;
        font-weight: 700;
        color: colors.$dark-gray-bravo;
    }
    .card-sub{
        transition: all 200ms ease-in-out;
        font-size: 14px;
        font-weight: 400;
        color: colors.$dark-gray-alpha;
    }
}

.stats-container{
    display: flex;
    gap: 32px;
}

.lower-container{
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.project-h-container{
    @include mixins.font(700);
    display: flex;
    align-items: center;
    height: 220px;
    width: 506px;
    border: 1px solid colors.$light-gray-alpha;
    border-radius: 8px;
    .project-h-image{
        padding: 32px 0 32px 32px;
        height: 100%;
        img{
            margin-top: 35px;
            width: 113px;
            height: 113px;
            object-fit: cover;
            border-radius: 50%;
        }
    }
    .project-h-content{
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 32px;
    }
    .project-h-text{
        display: flex;
        flex-direction: column;
        gap: 8px;
        color: colors.$dark-gray-alpha;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }
    .project-h-stats{
        display: flex;
        gap: 32px;
        color: colors.$dark-gray-bravo;
        p {
            font-size: 14px;
            font-weight: 400;
        }
        strong {
            font-size: 16px;
            font-weight: 700;
        }
    }
    @media (max-width: breakpoints.$breakpoint-tablet) {
        max-width: 85%;
        flex-direction: column;
        align-items: center;
        text-align: center;
        height: fit-content;
        .project-h-image{
            padding: 12px;
            margin: 0;
        }
        .project-h-content{
            padding: 12px;
            align-items: center;
        }
      }
}