@use "../../global-styles/colors";
@use "../../global-styles/breakpoints";

.investor-toolbar-layout{
    display: grid;
    width: -webkit-fill-available;
    grid-template-columns: 3fr 1.5fr 0.2fr;
    .sort-reset{
        width: 88px;
        display: flex;
        align-items: center;
        gap: 12px;
    }
    @media (max-width: breakpoints.$breakpoint-tablet) {
        display: flex;
        flex-direction: column;
        gap: 0;
        width: -webkit-fill-available;
        .sort-reset{
            display: flex;
            gap: 20px;
        }
      }
  }


  .upload-quaterly-report-container {
    align-self: flex-start;

    & .input-modal {
        width: auto;
        @media (max-width: breakpoints.$breakpoint-tablet) {
            width: 280px;
            & .input-file-main-container{
                width: auto;
            }
        }
    }
    
    & .input-file-main-container{
        padding-left: 0px;
        border-left: none;
        height: auto;
        margin-bottom: 24px;
    }
    & .input-file-container {
        height: auto;
        padding-top: 24px;
        padding-bottom: 24px;
    }
  }

  .table_email_column_width {
    display: flex;
    align-items: center;
  }