@use '../../../global-styles/colors';
@use '../../../global-styles/mixins';

.input-field {
  @include mixins.font();
  padding: 17px 16px;
  border: 1px solid colors.$light-gray-delta;
  border-radius: 8px;
  background-color: colors.$white;
  width: 100%;
  box-sizing: border-box;
  font-style: normal;
  font-size: 14px;
  line-height: 120%;
  color: colors.$light-gray-echo;
  margin: 8px 0px;
  &:hover {
    border: 1px solid colors.$dark-gray-alpha;
  }

  &:focus-visible {
    outline: none !important;
    color: colors.$dark-gray-bravo;
    border: 1px solid colors.$dark-gray-alpha;
  }
}

.react-datepicker__input-container {
  & input {
    @include mixins.font();
    padding: 17px 16px;
    border: 1px solid colors.$light-gray-delta;
    border-radius: 8px;
    background-color: colors.$white;
    width: 100%;
    box-sizing: border-box;
    font-style: normal;
    font-size: 14px;
    line-height: 120%;
    color: colors.$light-gray-echo;
    margin: 8px 0px;
    &:hover {
      border: 1px solid colors.$dark-gray-alpha;
    }

    &:focus-visible {
      outline: none !important;
      color: colors.$dark-gray-bravo;
      border: 1px solid colors.$dark-gray-alpha;
    }
  }
}
.success-input {
  border: 1px solid colors.$success;
}

.error-input {
  border: 1px solid colors.$error;
}

.input-label {
  @include mixins.font(700);
  margin-top: 8px;
  font-size: 14px;
  line-height: 140%;
  color: colors.$black;
}

.input-icon {
  margin-left: -35px;
  margin-top: 10px;
  z-index: 9999;
  position: absolute;
  margin-top: 23px;
}

.input-checkbox-container {
  display: flex;
  align-items: center;
  & .input-checkbox {
    height: 22px;
    width: 22px;
    accent-color: colors.$red-alpha;
  }
  & .input-checkbox-label {
    margin-left: 16px;
    @include mixins.font(400);
    font-size: 14px;
    line-height: 140%;
    color: colors.$dark-gray-alpha;
  }
}

.ml-16 {
  margin-left: 16px;
}
