
.bounce-1 {
    animation-name: bounce-1;
    animation-timing-function: linear;
    animation-duration: 3s;
    animation-iteration-count: infinite;
  }
  @keyframes bounce-1 {
    0%   { transform: translateY(0); }
    50%  { transform: translateY(8px); }
    100% { transform: translateY(0); }
  }
  