@use "../../../global-styles/colors";
@use "../../../global-styles/mixins";
@use "../../../global-styles/breakpoints";

$heading_font_weight : 700;

.h1-heading {
    @include mixins.font($heading_font_weight);
    font-size: 48px;
    line-height: 120%;
    letter-spacing: 0;
    color: colors.$dark-gray-bravo;
}

.h2-heading {
    @include mixins.font($heading_font_weight);
    font-size: 40px;
    line-height: 120%;
    letter-spacing: 0;
    color: colors.$dark-gray-bravo;
    @media (max-width: breakpoints.$breakpoint-mobile) {
       font-size: 36px;
       text-align: center;
      }
}

.h3-heading {
    @include mixins.font($heading_font_weight);
    font-size: 32px;
    line-height: 120%;
    letter-spacing: 0;
    color: colors.$dark-gray-bravo;
}

.h4-heading {
    @include mixins.font($heading_font_weight);
    font-size: 24px;
    line-height: 140%;
    letter-spacing: 0;
    color: colors.$dark-gray-bravo;
}

.h5-heading {
    @include mixins.font($heading_font_weight);
    font-size: 20px;
    line-height: 140%;
    letter-spacing: 0;
    color: colors.$dark-gray-bravo;
}

.h6-heading {
    @include mixins.font($heading_font_weight);
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0;
    color: colors.$dark-gray-bravo;
}