@use "../../../global-styles/colors";

.flex {
  display: flex;
  &.column-center {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &.row-center {
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  &.space-between {
    justify-content: space-between;
  }
  &.flex-end {
    justify-content: flex-end;
  }
  &.column{
    flex-direction: column;
  }
  &.align-center {
    align-items: center;
  }
}