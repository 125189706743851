@use "../../../global-styles/colors";


.default-bar {
    -webkit-appearance: none;
    width: 100%;
    height: 15px;
    background-color: colors.$blue-alpha;
    border-radius: 4px;
}
progress.default-bar::-webkit-progress-bar {
    background-color: colors.$light-gray-alpha;
    border-radius: 4px;
}
progress.default-bar::-webkit-progress-value {
    background-color: colors.$blue-alpha;
    border-radius: 4px;
}

.green-bar {
    -webkit-appearance: none;
    width: 100%;
    height: 4px;
    background-color: colors.$blue-bravo;
    border-radius: 4px;
}
progress.green-bar::-webkit-progress-bar {
    background-color: colors.$blue-bravo;
    border-radius: 4px;
}
progress.green-bar::-webkit-progress-value {
    background-color: colors.$blue-alpha;
    border-radius: 4px;
}

.red-bar {
    -webkit-appearance: none;
    width: 100%;
    height: 4px;
    background-color: colors.$red-alpha;
    border-radius: 4px;
}
progress.red-bar::-webkit-progress-bar {
    background-color: colors.$red-delta;
    border-radius: 4px;
}
progress.red-bar::-webkit-progress-value {
    background-color: colors.$red-alpha;
    border-radius: 4px;
}