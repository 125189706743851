@use "../../../global-styles/colors";
@use "../../../global-styles/breakpoints";

.cta-banner-container {
  width: 100%;
  height: 224px;
  background-color: colors.$light-gray-bravo;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0px 124px;
  @media (max-width: breakpoints.$breakpoint-mobile) {
    padding: 24px !important;
    display: flex;
    justify-content: center;
    text-align: center !important;
    & div p {
      text-align: center !important;
    }
  }
}


