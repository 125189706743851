@use "../../../global-styles/colors";
@use "../../../global-styles/mixins";

.pagination-container{
    display: flex;
    justify-content: center;
}

.items-container{
    display: flex;
    gap: 24px;
}

.page-item{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    width: 24px;
    font-size: 14px;
    font-weight: 400;
    padding: 8px;
    cursor: pointer;
    &:hover{
        background-color: colors.$light-gray-alpha;
        border-radius: 4px;
    }
    &.page-item-active{
        border: 1px solid colors.$light-gray-alpha;
        border-radius: 4px;
        color: colors.$white;
        font-weight: 700;
        background-color: colors.$dark-gray-bravo;
    }
}

.no-hover {
    &:hover {
        background-color: colors.$white;
    }
}