@use '../../global-styles/colors';
@use '../../global-styles/mixins';
@use '../../global-styles/breakpoints';

.incomplete-order {
  background-color: colors.$light-gray-golf;
}

.styled-table tbody tr {
  border-bottom: 1px solid colors.$light-gray-echo;
}

.styled-table .table-header-with-icon {
  min-width: 160px;
  &-content {
    display: flex;
    align-items: center;
    gap: 12px;
  }
}

.table-header-min-width div {
  display: flex;
  align-items: center;
}

.table-cell-with-icon {
  display: flex;
  align-items: center;
}
