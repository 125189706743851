@use "../../global-styles/colors";
@use "../../global-styles/mixins";
@use "../../global-styles/breakpoints";

.flex-align-center {
  align-items: center;
  @media (max-width: breakpoints.$breakpoint-extra-large-screen) {
    flex-direction: column-reverse;
    margin-left: 0px;
    & h1 {
      font-size: 28px;
      margin-top: 20px;
    }
    & p {
      font-size: 12px;
    }
  }
}
.project-sub-heading {
  @media (max-width: breakpoints.$breakpoint-extra-large-screen) {
    flex-direction: column;
    align-items: center;
  }
}

.banner-padding {
  @media (max-width: breakpoints.$breakpoint-extra-large-screen) {
    padding: 32px 12px;
  }
}
.default-property-card {
  height: 120px;
  width: 190px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: breakpoints.$breakpoint-extra-large-screen) {
    max-width: none;
    width: 100%;
    flex: 0.5;
    margin-right: 16px;
    height: 64px;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 0px 24px;
    & h3 {
      font-size: 24px;
    }
    & > p {
      margin: 0px;
    }
  }
  @media (max-width: breakpoints.$breakpoint-mobile) {
    flex: unset;
    margin-right: unset;
  }

}
.default-progress-card {
  height: 120px;
  width: 348px;
  display: flex;
  padding: 0px 32px;
  flex-direction: column;
  justify-content: space-evenly;
  @media (max-width: breakpoints.$breakpoint-extra-large-screen) {
    width: 100%;
    margin: 12px 0px;
  }
}
.width-auto-progress-card {
  @media (max-width: breakpoints.$breakpoint-extra-large-screen) {
    width: auto;
  }
}
.img-height {
  height: 512px;
  width: 90%;
  justify-content: flex-end;
  margin-right: 68px;
  @media (max-width: breakpoints.$breakpoint-extra-large-screen) {
    margin: 40px 0px;
    justify-content: center;
    width: 100%;
  }
  & > img {
    width: 100%;
  }
}
.company-card {
  background-color: colors.$light-gray-foxtrot;
  height: fit-content;
  margin-left: 32px;
  margin-right: 68px;
  @media (max-width: breakpoints.$breakpoint-extra-large-screen) {
    width: 100%;
    margin: 0px;
    & > p {
      margin-left: 32px;
    }
  }
}
.company-card-top-section {
  margin: 32px 32px 16px 32px;
  align-items: center;
  @media (max-width: breakpoints.$breakpoint-extra-large-screen) {
    justify-content: center;
    align-items: center;
    & h3 {
      font-size: 24px;
    }
  }
}
.company-card-btn {
  width: 85%;
}
.company-card-container {
  margin: 0px 32px;
}
.company-property-card {
  background-color: colors.$white;
  height: 105px;
  width: 166px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &:first-child {
    margin-right: 16px;
  }
  &:nth-child(3) {
    margin-right: 16px;
  }
  @media (max-width: breakpoints.$breakpoint-tablet) {
    &:first-child {
      margin-right: 0px;
    }
    &:nth-child(3) {
      margin-right: 0px;
    }
    width: 130px;
    height: 85px;
    & h3 {
      font-size: 24px;
    }
  }
}
.company-card-checkbox-section {
  row-gap: 16px;
  & span {
    color: #2c3941;
  }
  @media (max-width: breakpoints.$breakpoint-extra-large-screen) {
    & span {
      font-size: 12px;
    }
  }
}
.detail-layout {
  justify-content: space-between;
  @media (max-width: breakpoints.$breakpoint-extra-large-screen) {
    flex-direction: column;
  }
}

.company-details {
  width: 60%;
  @media (max-width: breakpoints.$breakpoint-extra-large-screen) {
    width: 100%;
    margin: 0px;
    & p {
      text-align: center;
    }
    & img {
      height: 460px;
    }
  }
}
.deal-contour-info {
  @media (max-width: breakpoints.$breakpoint-extra-large-screen) {
    width: 100%;
    margin-top: 80px;
    margin-left: 0px;
  }
}
.project-sub-info {
  @media (max-width: breakpoints.$breakpoint-large-screen) {
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
}
.client-engagement{
  padding-right: 82px;
  @media (max-width: breakpoints.$breakpoint-tablet) {
    padding: 0px;
  }
}
.project-cover-image{
  max-width: 500px;
  max-height: 500px;
}
.client-engagement-images{
  max-width: 180px;
  max-height: 180px;
}