@use "../../../global-styles/mixins";

.footer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  bottom: 0px;
  width: 100%;
  background-color: #fff;
  border-top: 1px solid #e2e2e2;
  margin-top: 48px;
  padding: 8px 0px;
}

