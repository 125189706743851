@use "../../global-styles/colors";
@use "../../global-styles/breakpoints";

.slider-container{
    display: flex;
    max-width: 90vw;
    gap: 32px;
    overflow-x: auto;
  }

  .toolbar-layout{
    display: grid;
    width: -webkit-fill-available;
    grid-template-columns: 3fr 1.2fr 1.3fr;
    .sort-reset{
        display: flex;
        align-items: center;
        gap: 12px;
    }
    @media (max-width: breakpoints.$breakpoint-tablet) {
        display: flex;
        flex-direction: column;
        gap: 0;
        width: -webkit-fill-available;
        .sort-reset{
            display: flex;
            gap: 20px;
        }
      }
  }

  .project-cards-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-template-columns: 1fr 1fr 1fr;
  max-width: 90vw;
  margin-bottom: 32px;
    @media (min-width: breakpoints.$breakpoint-large){
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.last-mnt{
    text-align: left;
    margin: 48px 0;
}

.slider-btn-container{
    display: flex;
    gap: 24px;
    margin-right: 20px;
    p{
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 24px;
        width: 24px;
        padding: 12px 12px;
        border: 1px solid colors.$light-gray-alpha;
        border-radius: 8px;
        transition: all 300ms ease;
        &:hover{
            background-color: colors.$light-gray-alpha;
        }
        &:active{
            color: colors.$white;
            background-color: colors.$light-gray-echo;
        }
    }
    @media (max-width: breakpoints.$breakpoint-tablet) {
        margin-bottom: 24px;
      }
}

.slider-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 48px;
    @media (max-width: breakpoints.$breakpoint-mobile) {
        justify-content: center;
      }
}

.reset-btn{
    max-width: 88px;
  }