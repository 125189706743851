// we will follow NATO Phonetic Alphabet to differentiate 2 same color by using postfix.
// like Alpha,Bravo,Charlie,Delta,Echo,Foxtrot,Golf,Hotel,India,Juliet,Kilo,Lima,Mike,November,Oscar,Papa,Quebec,Romeo,Sierra,Tango,Uniform,Victor,Whiskey,X-ray,Yankee,Zulu

// Primary Color
$red-alpha: #F53B4D;
$red-bravo: linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15), #F53B4D);
$red-charlie: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15), #F53B4D);
$red-delta: #FFE5E8;

// Secondary Color
$white: #ffffff;
$light-gray-alpha: #E2E2E2;
$light-gray-bravo: #F7F7F7;
$light-gray-charlie: rgba(255, 255, 255, 0.01);
$light-gray-delta: #DCDCDC;
$light-gray-echo: rgba(84, 90, 94, 0.7);
$light-gray-foxtrot : #F9F9F9;
$light-gray-golf : #ECECEC;


$dark-gray-alpha: #545A5E;
$dark-gray-bravo: #2C3941;
$dark-gray-charlie: #2E3349;


// Status Color
$success: #48B767;
$error: #DD0000;

$blue-alpha: #247CFF;
$blue-bravo: #E5EFFF;
$orange-alpha: #F2A32D;
$orange-bravo: #FFF5E5;
$orange-charlie: rgba(242, 163, 45, 0.1);

// input label color
$black: #000000;

// green color
$green-alpha: #48B767;
$green-bravo: #e0fee9;