@use "../../../global-styles/colors";
@use '../../../global-styles/mixins';

.basic {
    @include mixins.font(400);
    color: colors.$light-gray-echo;
}
.basic,.center {
    text-align: center;
}
.basic,.left {
    text-align: left;
}
.default-gray{
    color: colors.$dark-gray-alpha !important;
}
.default-blue{
    @include mixins.font(700);
    color: colors.$blue-alpha !important;
}
.default-red{
    @include mixins.font(700);
    color: colors.$red-alpha !important;
}
.small {
    font-size: 14px !important;
}

.regular {
    font-size: 16px;
}