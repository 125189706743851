@use "../../../global-styles/colors";
@use "../../../global-styles/mixins";

.banner-container {
    @include mixins.font(700);
    font-size: 16px;
    line-height: 120%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top:0;
    height: 40px;
    width: 100%;
    z-index: 10;
    & span{
        margin-left: 10px;
    }
}
.warning-banner {
    background-color: colors.$orange-bravo;
    color: colors.$orange-alpha;
}
.error-banner {
    background-color: colors.$red-delta;
    color: colors.$red-alpha;
}
.success-banner {
    background-color: colors.$green-bravo;
    color: colors.$green-alpha;
}