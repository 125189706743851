@use "../../../global-styles/colors";
@use "../../../global-styles/mixins";

.tag-container{
    @include mixins.font(400);
    font-size: 14px;
    padding: 6px 12px;
    border-radius: 10px;
    max-width: fit-content;
    max-height: fit-content;
    transition: all 200ms ease-in-out;
}

.black-bg{
    background-color: colors.$dark-gray-charlie;
    color: colors.$white;
}

.white-bg{
    background-color: colors.$white;
    border: 1px solid colors.$light-gray-alpha;
    color: colors.$black;
}