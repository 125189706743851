@use '../../global-styles/colors';
@use '../../global-styles/mixins';
@use '../../global-styles/breakpoints';

.field {
  &-container {
    width: 100%;
    margin-right: 24px;
  }
  &-input-label {
    @include mixins.font(700);
    margin-top: 8px;
    font-size: 14px;
    line-height: 140%;
    color: colors.$black;
  }
}
.unit-dropdown {
  width: 5vw;
}

.upload-img {
  &-cont {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 28px;
    position: relative;
    background-color: colors.$light-gray-bravo;
    height: 140px;
    width: 100px;
    border-radius: 7px;
    cursor: pointer;
    input {
      outline: 0;
      opacity: 0;
      width: 0;
      height: 0;
      position: absolute;
      z-index: -1;
      left: 0;
    }
    img {
      width: 100%;
    }
  }
  &-icon {
    text-shadow: 0px 0px 3px white;
    position: absolute;
    bottom: 10%;
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 40px;
    text-align: center;
    font-size: 40px;
    z-index: 0;
  }
  &-remove-icon {
    position: absolute;
    top: -5px;
    right: -5px;
    font-size: 22px;
    z-index: 2;
    cursor: pointer;
    background-color: white;
    border-radius: 100%;
    height: 21px;
    width: 22px;
    text-align: center;
  }
}

.project-image {
  height: 140px;
  width: 100px;
}

.toggle-container {
  flex-direction: column;
  align-items: end;
  row-gap: 12px;
}

.form-image-flex-direction {
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: breakpoints.$breakpoint-extra-large-screen) {
    overflow: scroll;
  }
}
.form-flex-direction {
  @media (max-width: breakpoints.$breakpoint-tablet) {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 12px;
  }
}

.project-form-error-message-wrapper {
  display: flex;
  flex-direction: column;
  text-align: end;
}
.project-form-save-button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 24px;
}

.publish-warning-sub-heading {
  color: colors.$orange-alpha;
  max-width: 477px;
  background-color: colors.$orange-charlie;
  padding: 16px 12px;
  border-radius: 8px;
  p {
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
  }
}

.key_investors_company_field {
  width: 491px;
  max-width: 491px;
  border-left: 8px solid #e2e2e2;
  padding-left: 24px;
  div.input-label {
    font-size: 18px;
  }
}
