@use '../../../global-styles/colors';

.tool-tip-container {
  padding: 10px;
  margin: 5px;
  width: 250px;
  border-radius: 5px;
  background-color: colors.$light-gray-alpha;
  color: colors.$dark-gray-bravo;
}

.tooltip {
  position: relative;
  display: flex;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 300px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 10px;
  border-radius: 6px;

  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
