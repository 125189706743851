@use "../../../global-styles/colors";
@use "../../../global-styles/mixins";

@mixin image-style {
  width: inherit;
  height: inherit;
  border-style: none;
}

.round {
  &-r8{
    @include image-style();
    border-radius: 50%;
    width: 80px;
    height: 80px;
  }
}

.all {
  &-r8{
    @include image-style();
    border-radius: 8px;
  }
  &-r16{
    @include image-style();
    border-radius: 16px;
  }
}

.top {
  &-r8{
    @include image-style();
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  &-r16{
    @include image-style();
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
}

.left {
  &-r8{
    @include image-style();
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  &-r16{
    @include image-style();
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }
}


.right {
  &-r8{
    @include image-style();
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  &-r16{
    @include image-style();
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  }
}

.bottom {
  &-r8{
    @include image-style();
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  &-r16{
    @include image-style();
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
}
